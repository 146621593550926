import MessageMap from '../i18n';

const enUS: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
  },
  FORMAT: {
    CURRENCY: 'USD',
  },
  SIDEBAR: {
    COMPANY_PROFILE_SETUP: 'Company profile setup',
    COMPANIES: 'Company profiles',
    STORES: 'Stores',
    VENDORS_GROUP: 'Sellers',
  },
  TOGGLE: {
    ACTIVE_TEXT: 'Active',
    INACTIVE_TEXT: 'Inactive',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Looks like this page is gone',
    NOT_FOUND_SUBHEADER_TEXT: "It looks like this page wasn't found or doesn't exist.",
    NOT_FOUND_HOME_BTN: 'Back',
    ERROR_GENERIC: 'Something went wrong',
    TOGGLE_ACTIVE_STATUS: 'Active',
    TOGGLE_INACTIVE_STATUS: 'Inactive',
    STEPPER_BACK_BUTTON: 'Go back',
    STEPPER_NEXT_BUTTON: 'Next',
  },
  HOME: {
    TITLE: 'Company profile setup',
    WELCOME: 'Welcome to BEES Company profile setup',
    WELCOME_DESCRIPTION:
      'Discover, access, and manage all stores and company profiles. Check all types of vendors, partners, and service models.',
    GET_TOOLS: 'Get to know the tools',
    GET_TOOLS_DESCRIPTION:
      'Quick access to all available tools for managing partners from all business models.',
    APP_STORES_TITLE: 'Stores',
    APP_STORES_DESCRIPTION: 'Access and manage stores from each zone and their companies.',
    APP_COMPANIES_TITLE: 'Company profiles',
    APP_COMPANIES_DESCRIPTION: 'Access and manage all company profiles registered on the app.',
  },
  COMPANY_LIST: {
    TITLE: 'Company profiles',
    TABLE_LEGAL_NAME: 'Legal name',
    TABLE_LEGAL_NAME_AND_GOVERNMENT_ID: 'Legal name and Tax ID',
    TABLE_GOVERNMENT_ID: 'Tax ID',
    TABLE_VENDOR_ID: 'Vendor ID',
    TABLE_TIER: 'Tier',
    TABLE_COUNTRY: 'Country',
    TABLE_VENDOR_SINCE: 'Vendor since',
    TABLE_SERVICE_MODEL: 'Service model',
    SEARCH: 'Search by',
    SEARCH_PLACEHOLDER: 'Search by legal name or tax ID',
    VENDOR_ID_TOOLTIP: 'Number automatically generated by our database',
    SEARCH_BY_LEGAL_NAME: 'Search by Legal name',
    SEARCH_BY_GOVERNMENT_ID: 'Search by Government ID',
    ADD_COMPANY: 'Add new company',
    EMPTY_VENDORS: 'There is no company registered at the moment.',
    WRONG_VENDORS_MESSAGE: 'Something went wrong.',
    WRONG_VENDORS_SUB_MESSAGE: "Sorry, we're having some technical issues. Try again later.",
    TABLE_CONTEXT_MENU_ADD_DDC_BTN: 'Add new DDC',
    VENDOR_ID_COPIED: 'Vendor ID copied to clipboard',
    FILTER_BY_COUNTRY: 'Filter by country',
    CANNOT_LOAD_SUPPORTED_COUNTRIES:
      "Your account doesn't have a Supported Country related. Talk to our support",
    COMPANY_CREATION_COUNTRY_NOT_SUPPORTED:
      'At the moment, the creation of a company for {countryName} is not supported',
    EMPTY_MESSAGE: 'No companies found.',
    LOADING_MESSAGE: 'Loading companies...',
    LINES_PER_PAGE: 'Lines per page: {pageSize}',
  },
  ADD_COMPANY: {
    TITLE: 'Add company',
    STEP1_LABEL: 'Company information',
    STEP1_DETAIL: 'Enter registration details.',
    STEP1_CONTENT_TITLE: 'Enter company information',
    STEP1_CONTENT_SUBTITLE: 'You can edit this information later.',
    STEP2_LABEL: 'Service model',
    STEP2_DETAIL: 'Select a service model type.',
    STEP2_CONTENT_TITLE: 'Add company',
    STEP2_CONTENT_SUBTITLE: 'This will define how the company will operate.',
    STEP2_CONTENT_TOOLTIP: 'P refers to the partner model. L refers to the logistics model.',
    CANCEL_BUTTON: 'Cancel',
    ADD_BUTTON: 'Add',
    TITLE_1P_1L_SINGLE_PROVIDER: '1P (1L) - Single provider',
    DESCRIPTION_1P_1L_SINGLE_PROVIDER: 'Products are sold and delivered by BEES.',
    TITLE_3P_3L_SAME_STORE: '3P (3L) - Same store',
    DESCRIPTION_3P_3L_SAME_STORE:
      'Products are sold in the ABI store and delivered by the supplier.',
    TITLE_3P_DISTRIBUTOR: '3P - Distributor',
    DESCRIPTION_3P_DISTRIBUTOR: 'Products are sold by BEES and delivered by a partner distributor.',
    TITLE_3P_3L_WHOLESALER: '3P - Wholesaler',
    DESCRIPTION_3P_3L_WHOLESALER: 'Products are sold and delivered by the wholesaler. ',
    TITLE_3P_3L_STOREFRONT: '3P (3L) - Storefront',
    DESCRIPTION_3P_3L_STOREFRONT:
      'Products are sold by the supplier at their storefront and delivered by them.',
    LEGAL_NAME_LABEL: 'Legal name',
    TRADE_NAME_LABEL: 'Trade name',
    GOVERNMENT_ID_LABEL: 'Government ID',
    GOVERNMENT_ID_TOOLTIP:
      'The number of characters may vary depending on the country of origin, special character not allowed.',
    TAX_ID_LABEL: 'Tax ID',
    TAX_ID_ERROR_LABEL: 'Enter a valid Tax ID. This field is required',
    ADDRESS_LABEL: 'Address',
    CITY_LABEL: 'City',
    STATE_LABEL: 'State',
    ZIP_CODE_LABEL: 'Zip code',
    COUNTRY_LABEL: 'Country',
    COMPANY_TIER_TITLE: 'Company tier',
    COMPANY_TIER_DESCRIPTION: 'Select the company account tier.',
    COMPANY_TIER_DEFAULT_LABEL: 'Silver',
    COMPANY_TIER_DEFAULT_DESCRIPTION: 'Regular information access.',
    COMPANY_TIER_SILVER_LABEL: 'Silver',
    COMPANY_TIER_SILVER_DESCRIPTION: 'Regular information access.',
    COMPANY_TIER_GOLD_LABEL: 'Gold',
    COMPANY_TIER_GOLD_DESCRIPTION: 'Paid feature access.',
  },
  VENDOR_FORM: {
    TITLE: 'Company information',
    DESCRIPTION: 'Start registering a new company by adding all the required information',
    LABEL_LEGAL_NAME: 'Legal name',
    LABEL_DISPLAY_NAME: 'Trade name',
    LABEL_GOVERNMENT_ID: 'Government ID',
    TOOLTIP_GOVERNMENT_ID:
      'The number of characters may vary depending on the country of origin, special character not allowed.',
    TOOLTIP_3P3L_ID:
      '3P (3L) Suppliers working as an outsourced logistic operation for BEES products',
    LABEL_LEGAL_ADDRESS: 'Company address',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_ZIP_CODE: 'Zip code',
    LABEL_COUNTRY: 'Country',
    LABEL_SERVICE_MODEL: 'Service model',
    REQUIRED: 'This field is required.',
    GOVERNMENT_ID_DUPLICATED: 'This government ID is already in use. Add a new ID and try again.',
  },
  SERVICE_MODEL: {
    SELECT_SERVICE_MODEL_TEXT: 'Select the type of service model you want to create.',
    TITLE_1P_1L: '1P (1L)',
    DESCRIPTION_1P_1L: 'Sold and delivered by BEES.',
    TITLE_1P_3L_REWARDS: '1P (3L) Rewards',
    DESCRIPTION_1P_3L_REWARDS: 'Sold by BEES with reward points and delivered by a supplier.',
    TITLE_3P_3L_SAME_STORE: 'Core/PRO Company - 3P (3L) Same Store',
    DESCRIPTION_3P_3L_SAME_STORE: 'Sold in the BEES app and delivered by a supplier.',
    TITLE_3P_3L_STOREFRONT: 'DTaaS Company - 3P (3L) Storefront',
    DESCRIPTION_3P_3L_STOREFRONT: 'Sold by a supplier in their storefront and delivered by them.',
    TITLE_3P_1L: '3P (1L)',
    DESCRIPTION_3P_1L: 'Sold by a supplier and delivered by BEES.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BR: 'Brazil',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'Canary Islands',
    PA: 'Panama',
    PE: 'Peru',
    PY: 'Paraguay',
    MX: 'Mexico',
    ZA: 'South Africa',
  },
  ACCESSES: {
    EDITOR: 'Editor',
    ADMIN: '{admin}',
    ORDER_MANAGER: 'Order Manager',
    PRODUCT_EDITOR: 'Product Editor',
    VIEWER: 'Viewer',
  },
  VENDOR: {
    VENDOR_ID: 'Vendor ID is a number generated automatically in our data base.',
  },
  PAGINATE: {
    ROWS_PER_PAGE: 'Rows per page',
  },
  VENDOR_SERVICE: {
    ERROR_LOAD_ALL: 'Unable to load all vendors.',
    CREATE_VENDOR_SUCCESS: 'A new company has been added',
    CREATE_VENDOR_ERROR_TITLE: 'Something went wrong',
    CREATE_VENDOR_ERROR_DESCRIPTION: 'Please, try again.',
  },
  USER_SERVICE: {
    ERROR_LOAD_ALL: 'Unable to load all users.',
    CREATE_USER_SUCCESS: 'A new user has been added',
    CREATE_VENDOR_ERROR: 'Something went wrong',
    CREATE_USER_FAIL: 'Unable to add a new user',
    CREATE_USER_ERROR_TITLE: 'Something went wrong',
    CREATE_USER_ERROR_DESCRIPTION: 'Please, try again.',
    RESEND_INVITE_SUCCESS: 'Invitation sent successfully',
    RESEND_INVITE_FAIL: 'There was a problem sending the invite, please try again',
    BLOCK_USER_SUCCESS: 'User deactivated successfully',
    BLOCK_USER_FAIL: 'Something went wrong when inactivating the user',
    UNBLOCK_USER_SUCCESS: 'User activated successfully',
    UNBLOCK_USER_FAIL: 'Something went wrong activating the user',
  },
  MODAL: {
    BACK: 'Back',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    CLOSE: 'Close',
    FINISH: 'Finish',
    GO_BACK: 'Go Back',
    NEXT: 'Next',
    CONFIRM: 'Confirm',
    TRY_AGAIN_FAILED: 'Try again failed',
  },
  COMPANY_DETAILS: {
    TITLE: 'General information',
    IMAGE_TITLE: 'Company logo',
    IMAGE_LABEL: 'Drag and drop or upload your company logo.',
    IMAGE_UNAUTHORIZED_LABEL: "You don't have permission to upload a company logo.",
    IMAGE_ERROR_LOADING: 'Error loading company logo.',
    IMAGE_NOT_AVAILABLE: 'Image not available',
    IMAGE_DIMENSIONS_ERROR: 'File dimensions are too big.',
    IMAGE_TYPE_ERROR: 'File type not allowed.',
    IMAGE_SIZE_ERROR: 'File size is too big.',
    UPLOAD_IMAGE_BTN: 'Upload image',
    IMAGE_INFO: 'We support JPG and PNG formats. Maximum size of 1MB and 300 x 300px.',
    LABEL_VENDOR_ID: 'Vendor ID',
    LABEL_VENDOR_ID_TOOLTIP: 'Vendor ID is a number generated automatically in our data base.',
    LABEL_COUNTRY: 'Country',
    LABEL_LEGAL_NAME: 'Legal name',
    LABEL_DISPLAY_NAME: 'Trade name',
    LABEL_GOV_ID: 'Government ID',
    LABEL_TAX_ID: 'Tax ID',
    LABEL_ADDRESS: 'Address',
    LABEL_TIER: 'Company tier',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_ZIP: 'Zip code',
    LABEL_SRV_MODEL: 'Service model',
    LABEL_SINCE: 'Vendor since',
    LABEL_DETAILS_TAB: 'Details',
    EDIT_BUTTON: 'Edit',
    DONE_EDITING_BUTTON: 'Done editing',
    CANCEL_BUTTON: 'Cancel',
    SAVE_CHANGES_BUTTON: 'Save changes',
    LAST_UPDATE: 'Last update:',
    LOAD_VENDOR_ERROR_DEFAULT: 'Something went wrong',
    UPDATE_VENDOR_SUCCESS_DEFAULT: 'Company successfully edited',
    UPDATE_VENDOR_ERROR_DEFAULT: 'Failed to update company information',
    CANCELLATION_MODAL_TITLE: 'Are you sure you want to continue?',
    CANCELLATION_MODAL_CONTENT:
      'There are unsaved changes that will be lost. If you proceed, you cannot undo this action.',
    CANCELLATION_MODAL_KEEP_EDITING_BTN: 'No, keep editing',
    CANCELLATION_MODAL_PROCEED_BUTTON: 'Yes, proceed',
    BTN_SAVE_CHANGES: 'Save changes',
    BTN_CANCEL: 'Cancel',
    EDIT_STORES: 'Edit stores',
    NO_ASSOCIATED_STORE: 'No associated store',
    ASSOCIATED_STORE_LABEL: 'Associated store',
    ASSOCIATE_MODAL_TITLE: 'Associate company with different stores',
    ASSOCIATE_MODAL_SUBTITLE:
      'Associate the company {vendorName} with different stores by selecting them.',
    CONFIRM_MODAL_TITLE: 'Confirm association?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Association in progress...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Processing finished',
    CONFIRM_MODAL_DESCRIPTION:
      'You are about to associate {vendorName} company with the selected stores.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Once the process is finished, you can close this modal or try again.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'Association has finished. You can now close this window or repeat the process.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'Association process has finished. You can now close this window or try again.',
    NO_STORES_SELECTED: 'No stores selected.',
    STORES_TO_ASSOCIATE: 'Stores to associate to',
    STORES_TO_DISSOCIATE: 'Stores to dissociate from',
    STORE_SUCCESS_STATUS: 'Success',
    STORE_FAILED_STATUS: 'Failed',
    SUCCESS_NOTIFY_MESSAGE: '{company} company was successfully updated.',
    SUCCESS_NOTIFY_BUTTON: 'Go to store list',
    NO_STORES_AVAILABLE_PLACEHOLDER: 'There is no store available to associate',
  },
  ITEMS_TAB: {
    LABEL_ITEMS_TAB: 'Items',
    LABEL_ITEMS_TITLE: 'Item Management',
    PAGE_CARD_MANUFACTURER_ID: 'Manufacturer ID',
    SKU_TITLE: 'SKU',
    REMOVE_BUTTON: 'Remove',
    PUBLISH_BUTTON: 'Publish',
    TOAST_MESSAGE_SUCCESS: 'SKUs Added successfully',
    TOAST_MESSAGE_ERROR: 'Something went wrong!',
  },
  COMPANY_SETTINGS_TAB: {
    LABEL_COMPANY_SETTINGS_TAB: 'Settings',
  },
  DISTRIBUTION_CENTER_TAB: {
    TITLE: 'Distribution centers',
    LABEL_DISTRIBUTION_CENTER_TAB: 'Distribution centers',
    TABLE_DISTRIBUTION_CENTER: 'Distribution center',
    TABLE_COVERAGE: 'Coverage',
    TABLE_DELIVERY_WINDOW: 'Delivery Range',
    EMPTY_EMAIL_LIST: 'There are no distribution centers at the moment.',
    ADD_DDC_BUTTON_LABEL: 'ADD NEW DDC',
    ERROR_TITLE: 'Something went wrong',
    ERROR_CONTENT: "Sorry, we're having some technical issues. Try again later.",
    RANGES: 'ranges',
    BUSINESS_DAYS: 'business days',
    TO: 'to ',
    AREAS: 'areas',
    TABLE_CONTEXT_MENU_EDIT_BTN: 'Edit',
    TABLE_CONTEXT_MENU_DELETE_BTN: 'Delete',
    CUSTOM_AREA: 'custom area',
    CUSTOM_AREAS: 'custom areas',
    DELETE_MODAL_TITLE: 'Delete distribution center',
    DELETE_MODAL_CANCEL: 'Cancel',
    DELETE_MODAL_DESCRIPTION: "Are you sure you want to delete ''{DCName}'' distribution center?",
    DELETE_MODAL_SUCCESS: 'Distribution center successfully deleted.',
    DELETE_MODAL_ERROR:
      "Distribution center couldn't be deleted due to a system error. Try again later.",
  },
  COMPANY_DETAILS_MODAL_FORM: {
    ADD_COMPANY_DETAILS_MODAL_TITLE: 'Add company',
    COMPANY_DETAILS_MODAL_TITLE: 'Edit vendor information',
    COMPANY_DETAILS_MODAL_SUB_TITLE:
      'Complete the information below to register a company. Once your company is registered, you can add members.',
    LABEL_COUNTRY: 'Country',
    LABEL_LEGAL_NAME: 'Legal name',
    LABEL_DISPLAY_NAME: 'Trade name',
    LABEL_GOV_ID: 'Government ID',
    GOV_ID_TOOLTIP:
      'The number of characters may vary depending on the country of origin, special character not allowed.',
    LABEL_LEGAL_ADDRESS: 'Company address',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_ZIP: 'Zip code',
    LABEL_SRV_MODEL: 'Service model',
    REQUIRED_ERROR_MESSAGE: 'This field is required',
    SUBMIT_BUTTON: 'Save',
    ADD_COMPANY_BUTTON: 'Add Company',
    ADD_COMPANY_CANCEL_BUTTON: 'Cancel',
    ERROR_MODAL_TITLE: 'Something went wrong',
    ERROR_MODAL_SUB_TITLE: "Sorry, we're having some technical issues. Try again later",
    GO_BACK_BUTTON_LABEL: 'Go Back',
    CLOSE_BUTTON_LABEL: 'Close',
  },
  GOV_ID_COMPONENT: {
    LABEL: 'Government ID',
    INVALID: 'Invalid Government ID',
  },
  ADD_USER_MODAL: {
    SUBMIT_BUTTON_TITLE: 'Add',
    CANCEL_BUTTON_TITLE: 'Cancel',
    CLOSE_BUTTON_TITLE: 'Close',
    MODAL_SUB_TITLE: 'E-mails added in this step will receive a link to access the Partner Portal',
    MODAL_TITLE: 'Add users',
    ERROR_MODAL_TITLE: 'Something went wrong',
    ERROR_MODAL_SUB_TITLE: "Sorry, we're having some technical issues. Try again later",
    GO_BACK_BUTTON_LABEL: 'Go Back',
    CLOSE_BUTTON_LABEL: 'Close',
    REQUIRED_ERROR_MESSAGE: 'This field is required',
    INVALID_EMAIL_ERROR_MESSAGE: 'Invalid e-mail format',
    DUPLICATED_EMAIL_ERROR_MESSAGE: 'Email already exists',
    DUPLICATED_EMAIL_INPUT_ERROR_MESSAGE: 'The entered data is already being used in another row',
    ADD_USER_ERROR_DEFAULT: 'Something went wrong',
  },
  FORMIK_USER_LIST: {
    EMAIL: 'E-mail',
    ACCESS: 'Access',
  },
  COMPANY_USERS: {
    TITLE: 'User management',
    LABEL_USERS_TAB: 'Users',
    TABLE_NAME: 'Name',
    TABLE_EMAIL: 'E-mail',
    TABLE_ACCESS: 'Access',
    TABLE_STATUS: 'Status',
    EMPTY_EMAIL_LIST: 'There are no users registered at the moment.',
    BUTTON_LABEL: 'ADD USER',
    RESEND_INVITE_BUTTON: 'RESEND INVITE',
    BLOCK_USER_BUTTON: 'DEACTIVATE USER',
    UNBLOCK_USER_BUTTON: 'ACTIVATE USER',
  },
  BLOCK_UNBLOCK_USER_MODAL: {
    BLOCK_BUTTON: 'DEACTIVATE',
    UNBLOCK_BUTTON: 'ACTIVATE',
    BLOCK_TITLE: 'Deactivate user',
    UNBLOCK_TITLE: 'Activate user',
    CANCEL: 'CANCEL',
    BLOCK_TEXT:
      "You are about to deactivate this account. The user won't be able to access the platform after that. You can activate the account whenever you want.",
    UNBLOCK_TEXT: 'You are about to activate this user. Are you sure you want to proceed?',
  },
  USER_STATUS: {
    PENDING: 'Pending',
    BLOCKED: 'Inactive',
    ACTIVE: 'Active',
  },
  ACCOUNT_TIER: {
    LABEL_ACCOUNT_TIER: 'Account tier',
    DESCRIPTION_ACCOUNT_TIER_CREATE: 'Select the type of account you are creating.',
    DESCRIPTION_ACCOUNT_TIER_EDIT: 'Select the type of account.',
    LABEL_TIER_SILVER: 'Silver',
    DESCRIPTION_TIER_SILVER: 'Access only the regular information.',
    LABEL_TIER_DEFAULT: 'Silver',
    DESCRIPTION_TIER_DEFAULT: 'Access only the regular information.',
    LABEL_TIER_GOLD: 'Gold',
    DESCRIPTION_TIER_GOLD: 'Access paid features.',
    LABEL_CHANGE_TIER: 'Change tier',
    LABEL_EDIT_TIER: 'Edit tier',
    FETCH_AVAILABLE_TIERS_ERROR: 'Unable to load the country information. Please select it again.',
    FETCH_AVAILABLE_TIERS_SOMETHING_WENT_WRONG:
      'Something went wrong when trying load country information.',
    CHANGE_TIER_SUCCESS_MESSAGE: 'Account tier successfully updated.',
    CHANGE_TIER_ERROR_MESSAGE: 'Unable to update account tier. Try again later.',
  },
  COMPANY_SETTINGS: {
    SAVE_CHANGES: 'Save changes',
    CANCEL: 'Cancel',
    EMPTIES_CONFIGURATION_TITLE: 'Empties configuration',
    EMPTIES_DESCRIPTION: 'Enable cart with empties only?',
    EMPTIES_DESCRIPTION_TOOLTIP: 'Empties are returnable products that can be reused or recycled.',
    PAY_AND_REDEEM_TITLE: 'Pay and redeem',
    PAY_AND_REDEEM_SUBTITLE: 'Select the partners payment method.',
    PAYMENT_METHOD_INPUT_LABEL: 'Minimum order amount',
    BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL: 'Days to expire',
    CASH_PAYMENT_METHOD_NAME: 'Cash',
    BANK_SLIP_PAYMENT_METHOD_NAME: 'Bank slip',
    BANK_DEPOSIT_PAYMENT_METHOD_NAME: 'Bank deposit',
    BANK_TRANSFER_PAYMENT_METHOD_NAME: 'Bank transfer',
    CASH_DEPOSIT_PAYMENT_METHOD_NAME: 'Cash deposit',
    EFT_PAYMENT_METHOD_NAME: 'EFT (Electronic funds transfer)',
    BANK_DEPOSIT_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order via bank deposit.',
    CASH_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order only in cash upon delivery',
    BANK_SLIP_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order with a bank slip',
    ORDER_NOTIFICATIONS_TITLE: 'Order notifications',
    ORDER_NOTIFICATIONS_SUBTITLE: 'Select the recipients.',
    ORDER_NOTIFICATIONS_NO_SALES_REP_LABEL: 'No sales reps',
    ORDER_NOTIFICATIONS_PRIMARY_SALES_REP_LABEL: 'Primary sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_LABEL: 'All sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_SUPERVISOR_LABEL: 'All sales reps + team leader/manager',
    ORDER_NOTIFICATIONS_EMAIL_LIST: 'Email list',
    ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE: 'Use commas to separate the emails',
    ORDER_NOTIFICATIONS_ALERT_WARNING:
      'Sales reps will only receive order notifications if their emails are previously registered at BEES. Please reach out to BEES Support if any questions.',
    REDEEM_DESCRIPTION:
      'Will they participate in Club B and allow orders to be fully redeemed with points?',
    REDEEM_DESCRIPTION_TOOLTIP:
      'Allowing customers to redeem products by paying fully with points, no minimum checkout amount is needed.',
    REDEEM_OPTION_YES: 'Yes',
    REDEEM_OPTION_NO: 'No',
    EMPTIES_OPTION_YES: 'Yes',
    EMPTIES_OPTION_NO: 'No',
    MINIMUM_ORDER_LABEL: 'Minimum order amount',
    MAXIMUM_ORDER_LABEL: 'Maximum order amount',
    SAVE_COMPANY_SETTINGS_SUCCESSFULLY: 'Changes saved successfully',
    SAVE_COMPANY_SETTINGS_FAILURE: 'Unable to save changes.',
    DUPLICATED_PAYMENT_METHOD: "There's already a payment method with the same name added.",
    PAYMENT_MANAGER_BTN: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_TITLE: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_DESCRIPTION: 'Select an existing payment method or add a new one.',
    PAYMENT_MANAGER_MODAL_EXISTING_LABEL: 'Select existing payment method',
    PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER: 'Select',
    PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS: 'No payment method available',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL: 'Create custom payment method',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN: 'Add',
    PAYMENT_MANAGER_MODAL_CONFIRM: 'Confirm',
    PAYMENT_MANAGER_MODAL_CANCEL: 'Cancel',
    CUSTOM_PAYMENT_MODAL_TITLE: 'Add new payment method',
    CUSTOM_PAYMENT_MODAL_DESCRIPTION: 'Define details for the new payment method.',
    CUSTOM_PAYMENT_MODAL_TOOLTIP:
      'This language is automatically set according to the partner zone.',
    CUSTOM_PAYMENT_MODAL_LABEL: 'Payment method name in {language}',
    CUSTOM_PAYMENT_MODAL_PLACEHOLDER: 'Example: {example}',
    CUSTOM_PAYMENT_MODAL_CONFIRM: 'Confirm',
    CUSTOM_PAYMENT_MODAL_BACK: 'Go back',
    INPUT_ENGLISH_LABEL: 'English',
    INPUT_ENGLISH_PLACEHOLDER: 'Cash',
    INPUT_PORTUGUESE_LABEL: 'Portuguese',
    INPUT_PORTUGUESE_PLACEHOLDER: 'Dinheiro',
    INPUT_SPANISH_LABEL: 'Spanish',
    INPUT_SPANISH_PLACEHOLDER: 'Efectivo',
    INPUT_CHINESE_LABEL: 'Chinese',
    INPUT_CHINESE_PLACEHOLDER: '现金',
    INPUT_FRENCH_LABEL: 'French',
    INPUT_FRENCH_PLACEHOLDER: 'Espèces',
    CANCELLATION_MODAL_TITLE: 'Are you sure you want to continue?',
    CANCELLATION_MODAL_CONTENT:
      'There are unsaved changes that will be lost. If you proceed, you cannot undo this action.',
    CANCELLATION_MODAL_KEEP_EDITING_BTN: 'No, keep editing',
    CANCELLATION_MODAL_PROCEED_BUTTON: 'Yes, proceed',
  },
  PAYMENT_AND_ORDERS: {
    TAB_TITLE: 'Payment and orders',
    PAYMENT_METHODS_TITLE: 'Payment methods',
    PAYMENT_METHODS_SUBTITLE: 'Select the partners payment method.',
    BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL: 'Days to expire',
    PAYMENT_MANAGER_BTN: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_TITLE: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_DESCRIPTION: 'Select an existing payment method or add a new one.',
    PAYMENT_MANAGER_MODAL_EXISTING_LABEL: 'Select existing payment method',
    PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER: 'Select',
    PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS: 'No payment method available',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL: 'Create custom payment method',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN: 'Add',
    PAYMENT_MANAGER_MODAL_CONFIRM: 'Confirm',
    PAYMENT_MANAGER_MODAL_CANCEL: 'Cancel',
    CUSTOM_PAYMENT_MODAL_TITLE: 'Add new payment method',
    CUSTOM_PAYMENT_MODAL_DESCRIPTION: 'Define details for the new payment method.',
    CUSTOM_PAYMENT_MODAL_TOOLTIP:
      'This language is automatically set according to the partner zone.',
    CUSTOM_PAYMENT_MODAL_LABEL: 'Payment method name in {language}',
    CUSTOM_PAYMENT_MODAL_PLACEHOLDER: 'Example: {example}',
    CUSTOM_PAYMENT_MODAL_CONFIRM: 'Confirm',
    CUSTOM_PAYMENT_MODAL_BACK: 'Go back',
    INPUT_ENGLISH_LABEL: 'English',
    INPUT_ENGLISH_PLACEHOLDER: 'Cash',
    INPUT_PORTUGUESE_LABEL: 'Portuguese',
    INPUT_PORTUGUESE_PLACEHOLDER: 'Dinheiro',
    INPUT_SPANISH_LABEL: 'Spanish',
    INPUT_SPANISH_PLACEHOLDER: 'Efectivo',
    INPUT_CHINESE_LABEL: 'Chinese',
    INPUT_CHINESE_PLACEHOLDER: '现金',
    INPUT_FRENCH_LABEL: 'French',
    INPUT_FRENCH_PLACEHOLDER: 'Espèces',
    INPUT_KOREAN_LABEL: 'Korean',
    INPUT_KOREAN_PLACEHOLDER: '현금',
    ORDER_CONFIGURATIONS_TITLE: 'Order configurations',
    MINIMUM_ORDER_LABEL: 'Minimum per order',
    MINIMUM_ORDER_SUBTITLE: 'Set a minimum for order quantity or total amount.',
    MINIMUM_ORDER_INPUT_LABEL: 'Minimum order total',
    MAXIMUM_ORDER_LABEL: 'Maximum per order',
    MAXIMUM_ORDER_SUBTITLE: 'Set a maximum for order quantity or total amount.',
    MAXIMUM_ORDER_INPUT_LABEL: 'Maximum order total',
    MINIMUM_MAXIMUM_OPTIONAL: '(optional)',
    MINIMUM_MAXIMUM_PRODUCT_QUANTITY: 'Product quantity',
    MINIMUM_MAXIMUM_ORDER_TOTAL: 'Order total',
    ORDER_NOTIFICATIONS_TITLE: 'Order notifications',
    ORDER_NOTIFICATIONS_SUBTITLE: 'Select recipients',
    ORDER_NOTIFICATIONS_NO_SALES_REP: 'No sales reps',
    ORDER_NOTIFICATIONS_PRIMARY_SALES_REP: 'Primary sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS: 'All sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_SUPERVISOR: 'All sales reps + team leader/manager',
    ORDER_NOTIFICATIONS_ALERT_WARNING:
      'Sales reps will only receive order notifications if their emails are previously registered at BEES. Please reach out to BEES Support if any questions.',
    ORDER_NOTIFICATIONS_EMAIL_LIST: 'Email list',
    ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE: 'Use commas to separate the emails',
    REDEEM_TITLE: 'Club B participation',
    REDEEM_DESCRIPTION:
      'Will they participate in Club B and allow orders to be fully redeemed with points?',
    REDEEM_DESCRIPTION_TOOLTIP:
      'Allowing customers to redeem products by paying fully with points, no minimum checkout amount is needed.',
    EMPTIES_CONFIGURATION_TITLE: 'Empties configuration',
    EMPTIES_DESCRIPTION: 'Enable cart with empties only?',
    EMPTIES_DESCRIPTION_TOOLTIP: 'This allows POCs to return empties without buying anything.',
    OPTION_YES: 'Yes',
    OPTION_NO: 'No',
  },
  RECOMMENDATION: {
    TAB_LABEL: `Recommendations{country, select,
      BR { and customers}
      other {}
    }`,
    PRODUCT_RECOMMENDATIONS: 'Product recommendations',
    SUGGESTED_ORDER_LABEL: 'Suggested order carousel',
    SUGGESTED_ORDER_DESCRIPTION:
      'Showcases products on the BEES app homepage to increase their visibility.',
    SUGGESTED_ORDER_PERSONALIZED_LABEL: 'Personalized',
    SUGGESTED_ORDER_PERSONALIZED_DESCRIPTION: 'Suggests products based on previous orders.',
    SUGGESTED_ORDER_DEFAULT_LABEL: 'Default',
    SUGGESTED_ORDER_DEFAULT_DESCRIPTION: 'Suggests a fixed list of products.',
    INVENTORY_LABEL: 'Inventory solutions',
    INVENTORY_DESCRIPTION: 'Options to manage inventory.',
    INVENTORY_LIMITED_LABEL: 'Limited',
    INVENTORY_LIMITED_DESCRIPTION:
      "The customer's order will be limited by the quantity of the inventory.",
    INVENTORY_BACKORDER_LABEL: 'Backorder',
    INVENTORY_BACKORDER_DESCRIPTION:
      'Allows the customer to continue with an order if the inventory is zero. The customer will be notified about the delivery changes.',
    UPSELLING_LABEL: 'Upselling pop-up',
    UPSELLING_DESCRIPTION: 'Recommends different products to boost sales.',
    REPLACEMENT_LABEL: 'Out of stock replacement',
    REPLACEMENT_DESCRIPTION:
      'If the inventory is zero, similar products will be suggested as replacements.',
  },
  FULFILLMENT: {
    ALLOWED_CUSTOMERS: 'Allowed customers',
    POC_TYPES_LABEL: 'Select which types of POC will be allowed to place orders.',
    POC_TYPES_CNPJ_LABEL: 'CNPJ only',
    POC_TYPES_CNPJ_DESCRIPTION: 'Only POCs registered with a CNPJ will be able to place orders.',
    POC_TYPES_CNPJ_AND_CPF_LABEL: 'CNPJ and CPF',
    POC_TYPES_CNPJ_AND_CPF_DESCRIPTION:
      'POCs registered with either a CPF or a CNPJ will be able to place orders.',
  },
  FORM_MESSAGES: {
    ERROR_REQUIRED_FIELD: 'This field is required',
    ERROR_VALID_NUMBER: 'Please enter a valid number',
    ERROR_BIGGER_THAN_ZERO: 'The value must be bigger than zero',
    ERROR_INTEGER_NUMBER: 'The value must be an integer number',
    ERROR_LESS_THAN: 'The value must be less than {value}',
    ERROR_MAXIMUM_GREATER_THAN_MINIMUM:
      'Maximum order value must be greater than minimum order value',
  },
  SUCCESS_MESSAGES: {
    CHANGES_SAVED_SUCCESSFULLY: 'Changes saved successfully.',
    COMPANY_ADDED_SUCCESSFULLY: 'Company successfully added.',
  },
  ERROR_MESSAGES: {
    UNABLE_TO_SAVE_CHANGES: 'Unable to save changes.',
    DUPLICATED_PAYMENT_METHOD: "There's already a payment method with the same name added.",
  },
};

export default enUS;
