import { PaymentMethodTypes } from '@company-settings/enums';
import type { CompanySettingsV2 } from '@company-settings/types';
import { useQuery } from '@tanstack/react-query';
import type { CompanyBFFApiError } from 'Api/Api.d';
import { getCompanySettingsV2 } from 'Api/query/getCompanySettingsV2';
import { useClusterContext } from 'context/cluster';
import { useStore } from 'effector-react';
import VendorDetailStore from 'stores/vendorDetail/VendorDetailStore';
import { queriesKeys } from './queriesKeys';

export interface UseGetCompanySettingsV2Result {
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  error: CompanyBFFApiError | null;
  companySettings?: CompanySettingsV2;
}

const prepareSettings = (settings: CompanySettingsV2): CompanySettingsV2 => {
  const availableCashPaymentMethod = settings.availablePaymentMethods.find(
    (payment) => payment.type === PaymentMethodTypes.Cash
  );
  const filteredAvailablePaymentMethods = settings.availablePaymentMethods.filter(
    (payment) => payment.type !== PaymentMethodTypes.Cash
  );
  if (availableCashPaymentMethod) {
    const checkoutSettings = settings.checkoutSettings && {
      ...settings.checkoutSettings,
      paymentMethods: [
        { ...availableCashPaymentMethod, enabled: false, description: '' },
        ...(settings.checkoutSettings.paymentMethods
          ? settings.checkoutSettings.paymentMethods
          : []),
      ],
    };
    return {
      ...settings,
      checkoutSettings,
      availablePaymentMethods: filteredAvailablePaymentMethods,
    };
  }
  return {
    ...settings,
    availablePaymentMethods: filteredAvailablePaymentMethods,
  };
};

export const useGetCompanySettingsV2 = (vendorId: string): UseGetCompanySettingsV2Result => {
  const { isDTC } = useClusterContext();
  const { vendor, isLoading: isLoadingVendor } = useStore(VendorDetailStore);
  const country = vendor?.country;

  const { isLoading, isError, error, data: companySettings, isFetching: isUpdating } = useQuery<
    CompanySettingsV2,
    CompanyBFFApiError
  >({
    queryKey: queriesKeys.settingsV2(vendorId, country),
    queryFn: () => getCompanySettingsV2(vendorId, country),
    select: prepareSettings,
    enabled: !isLoadingVendor && !!country && !isDTC,
    refetchOnMount: false,
  });

  return {
    isUpdating,
    isLoading,
    isError,
    error,
    companySettings,
  };
};
