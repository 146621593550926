import { useCompanySettingsTabAccess } from '@company-settings';
import { useHasPermission } from 'admin-portal-shared-services';
import permissions from 'config/permissions';
import { useCountryContext } from 'context/country';
import Countries, { AllCountries } from 'domains/Countries';
import { useToggles } from 'hooks/useToggles/useToggles';

interface Permissions {
  canAccessDDCTab: boolean;
  canAccessCompanySettingsTab: boolean;
  canAccessPaymentAndOrdersTab: boolean;
  canAccessItemsTab: boolean;
  canAccessCompanyManagement: boolean;
  canAccessRecommendationsTab: boolean;
  canEditCompanyDetails: boolean;
  canEditPaymentMethods: boolean;
  canEditPaymentAndOrders: boolean;
  canEditRecommendationsTab: boolean;
  canViewFulfillment: boolean;
  canViewStoreItem: boolean;
}

const canViewFulfillment = (country: AllCountries) => {
  const allowedCountries: Array<AllCountries> = [Countries.BR];

  const isCountryAllowed = allowedCountries.includes(country);

  return isCountryAllowed;
};

export const usePermissions = (): Permissions => {
  const { country } = useCountryContext();

  const { showManagePaymentMethods } = useToggles();

  // General
  const hasReadPermission = useHasPermission(permissions.CompanyManagement.READ);
  const hasWritePermission = useHasPermission(
    [permissions.CompanyManagementLocal.WRITE, permissions.CompanyManagementGlobal.WRITE],
    'OR'
  );

  // DDC
  const hasDDCReadingPermission = useHasPermission(
    [permissions.AdminPortalDC.DDC.READ, permissions.AdminPortalDC.DDCGlobal.READ],
    'OR'
  );

  // Items
  const hasItemsWritePermission = useHasPermission(permissions.CompanyManagementItems.WRITE);

  // Recommendations
  const hasRecommendationsPermission = useHasPermission(
    [permissions.Recommendations.WRITE, permissions.Recommendations.READ],
    'OR'
  );
  const hasRecommendationsWritePermission = useHasPermission(permissions.Recommendations.WRITE);

  // Payment and Orders
  const hasPaymentAndOrdersPermission = useHasPermission(
    [permissions.PaymentAndOrders.READ, permissions.PaymentAndOrders.WRITE],
    'OR'
  );
  const hasPaymentAndOrdersWritePermission = useHasPermission(permissions.PaymentAndOrders.WRITE);

  // Store
  const hasStorePermission = useHasPermission(
    [permissions.StoreManagement.READ, permissions.StoreManagement.WRITE],
    'OR'
  );

  return {
    canAccessCompanyManagement: hasReadPermission,
    canAccessDDCTab: hasDDCReadingPermission,
    canAccessItemsTab: hasItemsWritePermission,
    canAccessCompanySettingsTab: useCompanySettingsTabAccess(),
    canAccessPaymentAndOrdersTab: hasPaymentAndOrdersPermission,
    canAccessRecommendationsTab: hasRecommendationsPermission,
    canEditCompanyDetails: hasWritePermission,
    canEditPaymentMethods: hasPaymentAndOrdersWritePermission && showManagePaymentMethods,
    canEditPaymentAndOrders: hasPaymentAndOrdersWritePermission,
    canEditRecommendationsTab: hasRecommendationsWritePermission,
    canViewFulfillment: canViewFulfillment(country),
    canViewStoreItem: hasStorePermission,
  };
};
