import React, { Suspense, lazy, memo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Grid } from '@hexa-ui/components';
import { Error403 } from 'components/Error/403/403';
import PageLoading from 'components/PageLoading/PageLoading';
import { useClusterContext } from 'context/cluster';
import { usePermissions } from 'hooks/usePermissions';
import { useSidebarService } from 'hooks/useSidebarService';

export const BASENAME = '/company-management';
export const STORES_PAGE_URL = `${BASENAME}/stores`;
export const COMPANIES_PAGE_URL = `${BASENAME}/companies`;
export const ADD_COMPANY_PAGE_URL = `${BASENAME}/companies/add-company`;
export const COMPANY_DETAILS_PAGE_URL = `${BASENAME}/company-detail/:id`;
export const VENDORS_GROUP_PAGE_URL = `${BASENAME}/vendors-group`;

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CompanyListPage = lazy(() => import('./pages/CompanyListPage'));
const AddCompanyPage = lazy(() => import('./pages/AddCompanyPage'));
const CompanyDetailsPage = lazy(() => import('./pages/CompanyDetails'));

const Router = (): JSX.Element => {
  const { canAccessCompanyManagement } = usePermissions();
  const { isDTC } = useClusterContext();

  useSidebarService();

  if (!canAccessCompanyManagement) return <Error403 />;

  return (
    <BrowserRouter>
      <Grid.Container
        style={{
          rowGap: '1rem',
        }}
        type="fluid"
        sidebar
      >
        <Grid.Item
          xs={12}
          style={{
            flexDirection: 'column',
            padding: '0',
          }}
        >
          <Suspense fallback={<PageLoading />}>
            <Switch>
              <Route path={BASENAME} component={isDTC ? RedirectToCompaniesPage : HomePage} exact />
              <Route path={COMPANIES_PAGE_URL} component={CompanyListPage} exact />
              <Route
                path={ADD_COMPANY_PAGE_URL}
                component={isDTC ? RedirectToCompaniesPage : AddCompanyPage}
                exact
              />
              <Route path={COMPANY_DETAILS_PAGE_URL} component={CompanyDetailsPage} exact />
            </Switch>
          </Suspense>
        </Grid.Item>
      </Grid.Container>
    </BrowserRouter>
  );
};

const RedirectToCompaniesPage = (): JSX.Element => <Redirect to={COMPANIES_PAGE_URL} />;

export default memo(Router, () => true);
