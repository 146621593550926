import { useHasPermission } from 'admin-portal-shared-services';
import { useMemo } from 'react';

import { useFeatureToggle } from '@core/hooks/useFeatureToggle';
import FeatureTogglesV2 from 'config/featureTogglesV2';
import permissions from 'config/permissions';

export const useShowOrderNotifications = (): boolean => {
  const { isFeatureToggleActivated } = useFeatureToggle(
    FeatureTogglesV2.SHOW_ORDER_NOTIFICATIONS_CONTENT
  );

  const userHasScopes = useHasPermission(
    [
      permissions.CompanySettings.OrderNotification.READ,
      permissions.CompanySettings.OrderNotification.WRITE,
    ],
    'OR'
  );

  const hasPermissionToAccess = useMemo(() => isFeatureToggleActivated && userHasScopes, [
    isFeatureToggleActivated,
    userHasScopes,
  ]);

  return hasPermissionToAccess;
};
