import React from 'react';
import { useIntl } from 'react-intl';
import { Building, List3, Store, TreeDiagram } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { useClusterContext } from 'context/cluster';
import { BASENAME, COMPANIES_PAGE_URL, STORES_PAGE_URL, VENDORS_GROUP_PAGE_URL } from 'Router';
import { usePermissions } from './usePermissions';
import { useToggles } from './useToggles';

export const listIcon = (): JSX.Element => <List3 fr={undefined} />;
export const companyIcon = (): JSX.Element => <Building fr={undefined} />;
export const storeIcon = (): JSX.Element => <Store fr={undefined} />;
export const vendorsGroupIcon = (): JSX.Element => <TreeDiagram fr={undefined} />;

export const useSidebarService = (): void => {
  const { showStores, showVendorsGroup } = useToggles();
  const { canViewStoreItem } = usePermissions();
  const { isDTC } = useClusterContext();

  const { formatMessage } = useIntl();

  const sidebarProps = {
    items: [
      ...(!isDTC
        ? [
            {
              id: 'company-profile',
              title: formatMessage({ id: 'SIDEBAR.COMPANY_PROFILE_SETUP' }),
              icon: listIcon,
              path: BASENAME,
            },
          ]
        : []),
      ...(showStores && canViewStoreItem && !isDTC
        ? [
            {
              id: 'stores',
              title: formatMessage({ id: 'SIDEBAR.STORES' }),
              icon: storeIcon,
              path: STORES_PAGE_URL,
            },
          ]
        : []),
      {
        id: 'companies',
        title: formatMessage({ id: 'SIDEBAR.COMPANIES' }),
        icon: companyIcon,
        path: COMPANIES_PAGE_URL,
      },
      ...(showVendorsGroup && !isDTC
        ? [
            {
              id: 'vendors-group',
              title: formatMessage({ id: 'SIDEBAR.VENDORS_GROUP' }),
              icon: vendorsGroupIcon,
              path: VENDORS_GROUP_PAGE_URL,
            },
          ]
        : []),
    ].filter(Boolean),
    utils: [],
  };

  useSidebar(sidebarProps);
};
