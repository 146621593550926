import { Location } from 'history';
import QuantityOptions from 'types/QuantityOptions';

export const PAGE_DEFAULT = 0;
export const INITIAL_ROWS = 10;
export const ROWS_PER_PAGE = [
  QuantityOptions.firstOptions,
  QuantityOptions.secondOptions,
  QuantityOptions.thirdOption,
  QuantityOptions.fourthOption,
];

export const getCurrentSearchPageRows = (location: Location) => {
  const getQuery = new URLSearchParams(location.search);
  const pageSize = Number(getQuery.get('pageSize'));
  const page = Number(getQuery.get('page')); // The first page is 0, but we display 1

  return { pageSize, page };
};

export const getCurrentFilterSearch = (location: Location) => {
  const getQuery = new URLSearchParams(location.search);
  const partialLegalName = getQuery.get('partialLegalName');
  const partialGovernmentId = getQuery.get('partialGovernmentId');

  if (!partialLegalName) {
    return { filterType: 'partialGovernmentId', filterValue: partialGovernmentId };
  }

  return { filterType: 'partialLegalName', filterValue: partialLegalName };
};

export const getCurrentFilterSearchV2 = (location: Location) => {
  const getQuery = new URLSearchParams(location.search);
  const partialLegalName = getQuery.get('partialLegalName');
  const partialGovernmentId = getQuery.get('partialGovernmentId');

  if (partialGovernmentId) {
    return { filterType: 'partialGovernmentId', filterValue: partialGovernmentId };
  }
  if (partialLegalName) {
    return { filterType: 'partialLegalName', filterValue: partialLegalName };
  }

  return { filterType: '', filterValue: '' };
};

export const removeQueryParam = (location: Location, history: unknown, param: string) => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete(param);
  history?.replace({
    search: queryParams.toString(),
  });
};
