import { useFeatureToggle as featureToggleV2 } from '@core/hooks/useFeatureToggle';
import {
  useFeatureToggleServiceV2,
} from 'admin-portal-shared-services';
import FeatureToggleV2 from 'admin-portal-shared-services/dist/services/featureToggle/model/FeatureToggleV2';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';
import FeatureTogglesV2 from 'config/featureTogglesV2';
import { useCountryContext } from 'context/country';
import Countries, { AllCountries, CountriesUS } from 'domains/Countries';
import { useMemo } from 'react';

interface Toggles {
  showManagePaymentMethods: boolean;
  showOrderNotifications: boolean;
  showEmptiesConfiguration: boolean;
  showVendorsGroup: boolean;
  showStores: boolean;
  showManageStores: boolean;
}

const getToggleStatus = (storeToggles: FeatureToggleV2, toggleName: FeatureTogglesV2): boolean => {
  const toggleFromStore = storeToggles[toggleName];
  return toggleFromStore
    ? toggleFromStore.value
    : featureToggleV2(toggleName).isFeatureToggleActivated;
};

const showManagePaymentMethods = (storeToggles: FeatureToggleV2) => {
  const isFeatureActive = getToggleStatus(
    storeToggles,
    FeatureTogglesV2.SHOW_MANAGE_PAYMENT_METHODS
  );
  return isFeatureActive;
};

const showOrderNotifications = (storeToggles: FeatureToggleV2, country: AllCountries) => {
  const isFeatureActive = getToggleStatus(
    storeToggles,
    FeatureTogglesV2.SHOW_ORDER_NOTIFICATIONS_CONTENT
  );

  const allowedCountries: Array<AllCountries> = [CountriesUS.US];
  const isCountryAllowed = allowedCountries.includes(country);

  return isFeatureActive && isCountryAllowed;
};

const showEmptiesConfiguration = (
  storeToggles: FeatureToggleV2,
  country: AllCountries
): boolean => {
  const isFeatureActive = getToggleStatus(storeToggles, FeatureTogglesV2.SHOW_EMPTIES_FEATURE);

  const allowedCountries: Array<AllCountries> = [Countries.ZA];
  const isCountryAllowed = allowedCountries.includes(country);

  return isFeatureActive && isCountryAllowed;
};

const showVendorsGroup = (storeToggles: FeatureToggleV2): boolean => {
  const isFeatureActive = getToggleStatus(storeToggles, FeatureTogglesV2.SHOW_VENDORS_GROUP);
  return isFeatureActive;
};

const showStores = (storeToggles: FeatureToggleV2): boolean => {
  const isFeatureActive = getToggleStatus(storeToggles, FeatureTogglesV2.SHOW_STORES);
  return isFeatureActive;
};

const showManageStores = (storeToggles: FeatureToggleV2): boolean => {
  const isFeatureActive = getToggleStatus(storeToggles, FeatureTogglesV2.SHOW_MANAGE_STORES);
  return isFeatureActive;
};

export const useToggles = (): Toggles => {
  const { country } = useCountryContext();
  const service = useFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME);

  const store = service.getStore();

  const storeToggles = useMemo(() => store.getState(), []);

  return {
    showManagePaymentMethods: showManagePaymentMethods(storeToggles),
    showOrderNotifications: showOrderNotifications(storeToggles, country),
    showEmptiesConfiguration: showEmptiesConfiguration(storeToggles, country),
    showVendorsGroup: showVendorsGroup(storeToggles),
    showStores: showStores(storeToggles),
    showManageStores: showManageStores(storeToggles),
  };
};
