import { useClusterService } from 'admin-portal-shared-services';
import { Clusters, ClustersEnv } from 'domains/Clusters';
import React, { createContext, useContext, useMemo } from 'react';

interface ClusterContextData {
  clusterName: Clusters;
  isDTC: boolean;
}

interface ClusterProviderProps {
  children: React.ReactNode;
  clusterEnv: ClustersEnv;
}

const ClusterContext = createContext<ClusterContextData>({} as ClusterContextData);

const ClusterProvider = ({ children, clusterEnv }: ClusterProviderProps): JSX.Element => {
  const clusterName = useClusterService().getCluster() as Clusters;
  const isDTC = clusterEnv === ClustersEnv.DTC;

  const value = useMemo(
    () => ({
      clusterName,
      isDTC,
    }),
    [clusterName, isDTC]
  );

  return <ClusterContext.Provider value={value}>{children}</ClusterContext.Provider>;
};

const useClusterContext = (): ClusterContextData => {
  const context = useContext(ClusterContext);

  if (!Object.keys(context).length) {
    throw new Error('useClusterContext must be used within a ClusterProvider');
  }

  return context;
};

export { ClusterProvider, useClusterContext };
